import React, { useCallback, useState } from "react";

import ContentManagement from "./ContentManagement";
import UpdateDescription from "./UpdateDescription";


const DetailsManagement = ({ data, setData, setMainPage }) => {

    const [page, setPage] = useState('description');

    const [details, setDetails] = useState({
        slug: data.slug,
        name: data?.name ?? '',
        image_url: data?.image_url ?? '',
        content: data?.content ?? ''
    })

    const handleDescriptionBackClick = useCallback(() => {
        setMainPage('details')
    }, [])

    const handleDescriptionNextClick = useCallback(() => {
        setPage('content')
    }, [])

    switch (page) {
        case "description":
            return <UpdateDescription data={details} setData={setDetails} handleBackClick={handleDescriptionBackClick} handleNextClick={handleDescriptionNextClick} />;
        case 'content':
            return <ContentManagement
                setPage={setPage}
                isEdit={true}
                data={details}
                setData={setDetails}
                setMainPage={setMainPage}
                setMainDetails={setData} />

    }

}

export default React.memo(DetailsManagement)