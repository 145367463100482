import React from "react";


/**Import Styles And Assets */
import styles from "./styles/CardContainer.module.scss"

/**Import Package Component */
import { Row, Col } from "antd";
import _ from "lodash";
import { useSelector } from "react-redux";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const antIcon = <LoadingOutlined style={{ fontSize: 40, color: "grey" }} spin />;

const CardContainer = ({ setPage, setDetails }) => {

    const { status, data } = useSelector(state => state.library)

    const handleCardClick = (data) => {
        setDetails(data)
        setPage('details')
    }
    return (
        <div className={styles.cardContainer}>
            <div className={styles.addButtonContainer}>
                <button className={styles.addNew} onClick={() => setPage('new')}>
                    <span>+</span>Add New
                </button>
            </div>
            <Row gutter={[30, 40]} className={styles.contentContainer}>
                {!_.isEmpty(data) ? data.map((item, index) => {
                    const { title, image_url, link } = item
                    return < Col xxl={4} xl={6} lg={8} md={12} xs={24} className={styles.card} key={index} >
                        <div className={styles.cardContent}>
                            <div className={styles.imageContainer} onClick={() => handleCardClick(item)}>
                                <img src={image_url} alt="Card Image" />
                            </div>
                            <div className={styles.textContainer}>
                                <a className={styles.name} href={link} target="_">
                                    {title}
                                </a>

                            </div>
                        </div>
                    </Col>
                }) :
                    <div className={styles.emptyContainer}>
                        {(status == 'loading') ? <Spin indicator={antIcon} /> : <h1>No data found</h1>}
                    </div>
                }
            </Row >


        </div >
    )
}


export default React.memo(CardContainer)