import React, { useEffect, useState } from "react";

import CardContainer from "./CardContainer";
import { useDispatch, useSelector } from "react-redux";
import { getLibraryThunk } from "../../features/LibraryReducer";
import NewCategory from "./NewCategory";


const Library = () => {

    const dispatch = useDispatch()
    const { status } = useSelector(state => state.library);

    const [details, setDetails] = useState(undefined);
    const [page, setPage] = useState('card');

    useEffect(() => {
        if (status !== 'success') {
            dispatch(getLibraryThunk());
        }

    }, [])

    switch (page) {
        case 'card':
            return <CardContainer setPage={setPage} setDetails={setDetails} />;

        case 'new':
            return <NewCategory setPage={setPage} />

        case 'details':
            return <NewCategory setPage={setPage} details={details} isEdit={true} />


    }
}

export default React.memo(Library)