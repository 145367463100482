import { Axios } from "../hooks/useAxiosInstance";

export const StaticPage = (function () {


    const apis = () => ({
        get: async (api_token) => await getRecord(api_token),
        update: async (data, api_token) => await updateRecord(data, api_token),
    })

    async function getRecord(api_token) {
        const url = "admin/static-page";
        const option = {
            headers: {
                'Authorization': api_token,
            }
        }

        return await Axios.get(url, option);
    }
    async function updateRecord(data, api_token) {
        const url = "admin/static-page/";
        const option = {
            headers: {
                'Authorization': api_token,
            }
        }

        return await Axios.patch(url, data, option);
    }


    return apis();

})()
