import React from "react";

import "./TableLayout.css";

import { Table } from "antd";

import { HiOutlineFolder } from "react-icons/hi"


export default function TableLayout({ headers, data, status }) {

    const emptyContainer = <div className={'ant-empty-description'}>
        <span className='icon'><HiOutlineFolder /></span>
        <h1>No Data Found</h1>
    </div>


    return (

        <Table
            tableLayout="auto"
            loading={status !== "success"}
            locale={{ emptyText: emptyContainer }}
            columns={headers}
            dataSource={data}
            pagination={{ pageSize: 8, position: ["bottomCenter"], className: "pagination", hideOnSinglePage: true }}
            className={"table"}
            rowClassName={"row"}
        />

    )
}