import React, { useState, useEffect } from "react";

/**Import Styles And Assets */
import styles from "./styles/ContentManagement.module.scss"
import "./styles/index.css"

/**Import Package Component */
import { useDispatch } from "react-redux";
import Froalaeditor from 'froala-editor';
import { IoMdArrowBack } from "react-icons/io"




/**FLOARA PLUGINS */
import "froala-editor/js/plugins/image.min.js"
import "froala-editor/js/plugins/link.min.js"
import "froala-editor/js/plugins/inline_class.min.js"
import "froala-editor/js/plugins/lists.min.js"
import "froala-editor/css/plugins/image.min.css"

import { Axios } from "../../hooks/useAxiosInstance";
import { Toast } from "../../hooks/useToast";
import { updateStaticPageThunk } from "../../features/StaticPageReducer";


export default function ContentManagement({ setPage, data }) {

    /**       key code:          c   C   x   X  */
    const NOT_ALLOWED_KEYCODE = [18, 67, 39, 88]
    const TITLE_PAGE = 'Edit Page Content';
    const [editor, setEditor] = useState(undefined)
    const dispatch = useDispatch()

    useEffect(() => {

        Froalaeditor.DefineIcon('header', { NAME: 'Header' });
        Froalaeditor.RegisterCommand('header', {
            title: 'Title',
            icon: "<strong style='padding:0 12px'>H</strong>",
            focus: false,
            undo: false,
            refreshAfterCallback: false,
            callback: function () {
                // if (this.selection.text()) {
                //     this.format.applyStyle('font-size', '20px');
                // }

                this.html.insert("<p style='font-size:24px;font-weight:600;margin:0;' class='header'>Header</p>", true);
                this.undo.saveStep();
            }
        });
        Froalaeditor.DefineIcon('subheader', { NAME: 'SubHeader' });
        Froalaeditor.RegisterCommand('subheader', {
            title: 'Sub Header',
            icon: "<strong style='padding:0 12px'>SH</strong>",
            focus: false,
            undo: false,
            refreshAfterCallback: false,
            callback: function () {
                // if (this.selection.text()) {
                //     this.format.applyStyle('font-size', '20px');
                // }

                this.html.insert("<p style='font-size:18px !important;font-weight:600 !important;margin:10px 0 5px;' class='subHeader'> Sub-Header</p>");
                this.undo.saveStep();
            }
        });


        Froalaeditor.DefineIcon('para', { NAME: 'Para', });
        Froalaeditor.RegisterCommand('para', {
            title: 'Paragraph',
            icon: "<strong style='padding:0 12px'>P</strong>",
            focus: false,
            undo: false,
            refreshAfterCallback: false,
            callback: function () {
                // if (this.selection.text()) {
                //     this.format.applyStyle('font-size', '20px');
                // }
                this.selection.save();
                this.html.insert("<p style='font-size:17px;font-weight:400;margin:7px 0'>Description</p>");
                this.selection.restore();
            }
        });


        Froalaeditor.DefineIcon('clear', { NAME: 'remove', SVG_KEY: 'remove' });
        Froalaeditor.RegisterCommand('clear', {
            title: 'Clear HTML',
            focus: false,
            undo: true,
            refreshAfterCallback: false,
            callback: function (e) {
                this.html.set('');
                this.events.focus();
            }
        });

        Froalaeditor.DefineIcon('codeO', { NAME: 'GetCode', SVG_KEY: 'edit' });
        Froalaeditor.RegisterCommand('codeO', {
            title: 'Output',
            focus: false,
            undo: false,
            refreshAfterCallback: false,
            callback: function () {
                let html = this.html.get()
                const body = {
                    air_medical_transportation: html
                }
                dispatch(updateStaticPageThunk({ data: body, setPage }))
            }
        });

        setEditor(new Froalaeditor('div#editor', {
            /**Editor Config */
            attribution: false,
            disableRightClick: true,
            toolbarButtons: [['undo', 'redo', 'bold'], ['header', 'subheader', 'para', 'insertImage', 'insertLink', 'formatUL'], ['clear', 'codeO']],
            pluginsEnabled: ['image', 'link', 'inlineClass', 'lists'],
            imagePaste: false,
            imageMove: false,
            htmlAllowedTags: ['.*'],
            htmlRemoveTags: [''],
            linkNoReferrer: false,
            htmlUntouched: true,
            paragraphFormatSelection: true,
            pastePlain: true,
            htmlAllowedEmptyTags: ['p'],
            htmlDoNotWrapTags: ['span', 'a'],

            /**Image Config */
            imageEditButtons: ['replaceImage', 'imageRemove'],
            imageDefaultMargin: 0,
            imageDefaultDisplay: 'block',
            imageDefaultAlign: 'left',
            imageAllowedTypes: ['jpeg', 'jpg', 'png'],
            imageResizeWithPercent: false,
            imageResize: false,
            imageUploadURL: `${process.env.REACT_APP_DOMAIN}api/upload/image'`,
            imageAddNewLine: true,
            imageUploadRemoteUrls: false,

            /**Link Config */
            linkMultipleStyles: false,
            linkStyles: {
                'fr-strong': 'Thick',
                // 'ls-class-code': 'Highlighter',
                'acc-class-code': 'Accordian',
            },
            linkEditButtons: ['linkOpen', 'linkStyle', 'linkEdit',],

            events: {
                'keydown': function (e) {
                    if (e.ctrlKey && NOT_ALLOWED_KEYCODE.includes(e.keyCode)) {
                        e.stopPropagation();
                        e.preventDefault();
                        return false
                    }
                },
                'image.beforeUpload': function (images) {
                    // Do something here.
                    // this is the editor instance.
                    const formData = new FormData();
                    formData.append('image', images[0]);

                    Axios.post("upload/image", formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                        .then(data => {
                            this.html.insert(`
                                <div>
                                <img src="${data.link}" width="300" height="200"  style="border-radius:15px;margin : 12px 0">
                                </div>
                            `)
                        })
                        .catch(err => {
                            Toast(err.message, 'error', false)
                        })

                    return false
                },
                'contentChanged': function () {
                    this.html.cleanEmptyTags();
                },
                'initialized': function () {
                    this.html.set(data)
                }
            }
        }))



    }, [])


    const handleBackButton = () => {
        setPage('content')
    }

    return (
        <div className={styles.contentManagement}>
            <div className={styles.backContainer} onClick={handleBackButton}>
                <span className={styles.icon}><IoMdArrowBack /></span>
                <h3 className={styles.backText} >Back</h3>
            </div>

            <div className={styles.contentContainer}>
                <h1 className={styles.title}>{TITLE_PAGE}</h1>
                <div id="editor" className={styles.editor}>
                </div>


            </div>

        </div >
    )

}