import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMediaThunk } from "../../features/MediaReducer";
import Table from "./Table";
import NewCategory from "./NewCategory";

const Media = () => {

    const [details, setDetails] = useState(undefined);
    const [page, setPage] = useState('table')
    const { status } = useSelector(state => state.media);
    const dispatch = useDispatch()

    useEffect(() => {
        if (status !== 'success') {
            dispatch(getMediaThunk());
        }
    }, [])

    switch (page) {
        case 'table':
            return <Table setPage={setPage} setDetails={setDetails} />
        case 'new':
            return <NewCategory setPage={setPage} />
        case 'edit':
            return <NewCategory setPage={setPage} details={details} isEdit={true} />
    }

}

export default React.memo(Media)