import React, { useEffect, useState } from "react";

import styles from "./styles/AddOrUpdateBlog.module.scss"
import "./styles/Editor.css"

import { useDispatch } from "react-redux";
import { IoMdArrowBack } from "react-icons/io"

import Froalaeditor from 'froala-editor';

/**FLOARA PLUGINS */
import "froala-editor/js/plugins/link.min.js"
import "froala-editor/js/plugins/lists.min.js"
import { createBlogThunk, updateBlogThunk } from "../../features/BlogReducer";


const AddOrUpdateBlog = ({ isEdit = false, data, setData, setPage, setMainPage }) => {

    const NOT_ALLOWED_KEYCODE = [18, 67, 39, 88]
    const TITLE_PAGE = isEdit ? 'Edit Blog' : 'Add Blog';
    const [editor, setEditor] = useState(undefined)
    const dispatch = useDispatch()

    useEffect(() => {

        Froalaeditor.DefineIcon('header', { NAME: 'Header' });
        Froalaeditor.RegisterCommand('header', {
            title: 'Title',
            icon: "<strong style='padding:0 12px'>H</strong>",
            focus: false,
            undo: false,
            refreshAfterCallback: false,
            callback: function () {
                // if (this.selection.text()) {
                //     this.format.applyStyle('font-size', '20px');
                // }

                this.html.insert("<p style='font-size:20px;font-weight:600;margin:0 0 5px;'>Header</p>", true);
                this.undo.saveStep();
            }
        });

        Froalaeditor.DefineIcon('para', { NAME: 'Para', });
        Froalaeditor.RegisterCommand('para', {
            title: 'Paragraph',
            icon: "<strong style='padding:0 12px'>P</strong>",
            focus: false,
            undo: false,
            refreshAfterCallback: false,
            callback: function () {
                // if (this.selection.text()) {
                //     this.format.applyStyle('font-size', '20px');
                // }
                this.selection.save();
                this.html.insert("<p style='font-size:17px;font-weight:400;margin:7px 0'>Description</p>");
                this.selection.restore();
            }
        });

        Froalaeditor.DefineIcon('codeO', { NAME: 'GetCode', SVG_KEY: 'edit' });
        Froalaeditor.RegisterCommand('codeO', {
            title: 'Output',
            focus: false,
            undo: false,
            refreshAfterCallback: false,
            callback: function () {
                let html = this.html.get()
                let body = {
                    ...data,
                    blog: html
                }
                if (!isEdit) {
                    dispatch(createBlogThunk({ data: body, setPage: setMainPage }))
                }
                else {
                    dispatch(updateBlogThunk({ data: body, setData, setPage }))
                }

            }
        });

        setEditor(() => new Froalaeditor('div#editor', {
            /**Editor Config */
            attribution: false,
            disableRightClick: true,
            listAdvancedTypes: true,
            toolbarButtons: [['undo', 'redo', 'bold',], ['header', 'para', 'formatUL', 'insertLink'], ['codeO']],
            pluginsEnabled: ['link', 'lists'],
            imagePaste: false,
            imageMove: false,
            htmlAllowedTags: ['.*'],
            htmlRemoveTags: [''],
            linkNoReferrer: false,
            htmlUntouched: true,
            paragraphFormatSelection: true,
            pastePlain: true,
            htmlAllowedEmptyTags: ['p'],
            htmlDoNotWrapTags: ['span', 'a'],


            /**Link Config */
            linkMultipleStyles: false,
            linkEditButtons: ['linkOpen', 'linkEdit'],

            events: {
                'keydown': function (e) {
                    if (e.ctrlKey && NOT_ALLOWED_KEYCODE.includes(e.keyCode)) {
                        e.stopPropagation();
                        e.preventDefault();
                        return false
                    }
                },
                'contentChanged': function () {
                    this.html.cleanEmptyTags();
                },
                'initialized': function () {
                    this.html.set(data?.blog)
                }
            }
        }))

    }, [])


    const handleBackButton = () => {
        editor.html.cleanEmptyTags();
        setData(prev => {
            return {
                ...prev,
                blog: editor.html.get()
            }
        })
        setPage('description')
    }

    return (
        <div className={styles.addOrUpdateBlog}>
            <div className={styles.backContainer} onClick={handleBackButton}>
                <span className={styles.icon}><IoMdArrowBack /></span>
                <h3 className={styles.backText} >Back</h3>
            </div>

            <div className={styles.contentContainer}>
                <h1 className={styles.title}>{TITLE_PAGE}</h1>
                <div id="editor" className={styles.editor}>
                </div>
            </div>


        </div>
    )
}

export default React.memo(AddOrUpdateBlog)