import React, { useState } from "react";

/**Import Styles And Assets */
import styles from "./styles/AddOrUpdateDescription.module.scss";

/**Import Package Component */
import { IoMdArrowBack } from "react-icons/io"
import _ from "lodash"
import moment from "moment"
import { DatePicker, Input } from 'antd';

const AddOrUpdateDescription = ({ isEdit = false, data, handleTextChange, handleBackButton, handleNextButton }) => {
    const TITLE_PAGE = isEdit ? 'Edit Blog Details' : 'Add Blog Details'

    return (
        <div className={styles.addOrUpdateDescription}>
            <div className={styles.backContainer} onClick={handleBackButton}>
                <span className={styles.icon}><IoMdArrowBack /></span>
                <h3 className={styles.backText} >Back</h3>
            </div>
            <div className={styles.contentContainer}>
                <h1 className={styles.title}>{TITLE_PAGE}</h1>
                <div className={styles.fieldsContainer}>
                    <div className={styles.fields}>
                        <p className={styles.label}>Blog Name</p>
                        <Input type="text" value={data.title} onChange={(e) => handleTextChange('title', e.target.value)} />
                    </div>
                    <div className={styles.fields}>
                        <p className={styles.label}>Blog Author</p>
                        <Input type="text" value={data.author} onChange={(e) => handleTextChange('author', e.target.value)} />
                    </div>
                    <div className={styles.fields}>
                        <p className={styles.label}>Created At</p>
                        <DatePicker value={data.blog_date ? moment(data.blog_date) : null} onChange={(e) => handleTextChange('blog_date', e ?? null)} />
                    </div>
                </div>
                <button className={styles.createCancerButton} onClick={handleNextButton}>
                    Next
                </button>
            </div>
        </div>
    )
}

export default React.memo(AddOrUpdateDescription)