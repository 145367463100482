import React, { useCallback, useState } from "react";
import ViewBlog from "./ViewBlog";
import AddOrUpdateDescription from "./AddOrUpdateDescription";
import AddOrUpdateBlog from "./AddOrUpdateBlog";

const BlogManagement = ({ data, setMainPage }) => {

    const [page, setPage] = useState('details');
    const [details, setDetails] = useState(data);

    const handleViewBackButton = useCallback(() => {
        setMainPage('card')
    }, [])

    const handleViewEditButton = useCallback(() => {
        setPage('description')
    }, [])

    const handleDescriptionBackButton = useCallback(() => {
        setDetails(data);
        setPage('details')
    }, [])

    const handleDescriptionNextButton = useCallback(() => {
        setPage('blog');
    }, [])

    const handleBlogBackButton = useCallback(() => {
        setPage('description');
    }, [])


    const handleTextChange = useCallback((name, value) => {
        setDetails(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }, [])

    switch (page) {
        case 'details':
            return <ViewBlog
                data={details}
                setData={setDetails}
                handleViewBackButton={handleViewBackButton}
                handleViewEditButton={handleViewEditButton}
                setMainPage={setMainPage}
            />

        case 'description':
            return <AddOrUpdateDescription
                isEdit={true}
                data={details}
                handleBackButton={handleDescriptionBackButton}
                handleNextButton={handleDescriptionNextButton}
                handleTextChange={handleTextChange}
            />
        case 'blog':
            return <AddOrUpdateBlog
                isEdit={true}
                data={details}
                setData={setDetails}
                setPage={setPage}
                setMainPage={setMainPage}
                handleBackButton={handleBlogBackButton}
            />
    }
}

export default React.memo(BlogManagement)