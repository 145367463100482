import React from "react";

import styles from "./styles/Content.module.scss";
import { useSelector } from "react-redux";

const Content = ({ data, handleEditButton }) => {


    return (
        <div className={styles.content}>
            <div className={styles.headerContainer}>
                <div className={styles.editButtonContainer}>
                    <button className={`${styles.edit} ${styles.buttons}`} onClick={handleEditButton}>
                        Edit
                    </button>
                </div>
            </div>

            <div className={`${styles.contentContainer} htmlContainer`} dangerouslySetInnerHTML={{ __html: data }} />


        </div >
    )
}

export default React.memo(Content)