import React, { useState } from "react";


/**Import Styles And Assets */
import styles from "./styles/NewCategory.module.scss"


/**Import Package Component */
import { IoMdArrowBack } from "react-icons/io"
import { AiOutlinePlus } from "react-icons/ai"
import { Upload } from 'antd';
import _ from "lodash"

/**Import Component */
import { Toast } from "../../hooks/useToast";


const NewCategory = ({ setPage, data, setData }) => {
    const TITLE_PAGE = 'Create Cancer Category'
    const UPLOAD_BUTTON = (
        <div>
            <AiOutlinePlus />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );


    const [imageUrl, setImage] = useState({});


    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
        if (!isJpgOrPng) {
            Toast('You can only upload JPG/PNG/JPEG file!', 'error', false);
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            Toast('Image must smaller than 2MB!', 'error', false);
        }
        return isJpgOrPng && isLt2M;
    };

    const handleImageChange = (info) => {
        setData(prev => {
            return {
                ...prev,
                image_url: info?.file?.originFileObj
            }
        })
    }

    const handleTextChange = (e) => {
        setData(prev => {
            return {
                ...prev,
                name: e.target.value
            }
        })
    }

    const handleBackButton = () => {
        setPage('card')
        setData({})
    }

    const handleNextButton = () => {
        if (_.isEmpty(data.name) || _.isEmpty(data.image_url)) {
            return Toast("Fields should not be empty", 'error', false)
        }

        setPage("content")
    }

    return (
        <div className={styles.newCategory}>
            <div className={styles.backContainer} onClick={handleBackButton}>
                <span className={styles.icon}><IoMdArrowBack /></span>
                <h3 className={styles.backText} >Back</h3>
            </div>
            <div className={styles.contentContainer}>
                <h1 className={styles.title}>{TITLE_PAGE}</h1>
                <div className={styles.fieldsContainer}>
                    <div className={styles.fields}>
                        <p className={styles.label}>Cancer Name</p>
                        <input type="text" value={data.name} onChange={handleTextChange} />
                    </div>
                    <div className={styles.fields}>
                        <p className={styles.label}>Add Image </p>
                        <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            accept="image/jpeg,image/png,image/jpg"
                            showUploadList={false}
                            action=""
                            beforeUpload={beforeUpload}
                            onChange={handleImageChange}
                        >
                            {!_.isEmpty(data.image_url) ? <img src={URL.createObjectURL(data.image_url)} alt="avatar" style={{ width: '100%' }} /> : UPLOAD_BUTTON}
                        </Upload>

                    </div>
                </div>
                <button className={styles.createCancerButton} onClick={handleNextButton}>
                    Next
                </button>
            </div>
        </div>
    )

}

export default React.memo(NewCategory)