import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Media } from '../Api/Media';
import { Toast } from '../hooks/useToast';

export const createMediaThunk = createAsyncThunk(
    'media/create',
    async ({ data, setPage, setIsSubmitted }, { getState }) => {
        Toast("Creating Media", "loading", true);
        try {
            const { api_token } = getState().profile.data;
            const response = await Media.create(data, api_token);

            Toast(response.message, "success", false);
            setPage("table")
            return response.data

        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err)
        }
        finally {
            setIsSubmitted(false)
        }
    }
)

export const updateMediaThunk = createAsyncThunk(
    'media/update',
    async ({ data, setPage, setIsSubmitted }, { getState }) => {
        Toast("Updating media", "loading", true);
        try {
            const { api_token } = getState().profile.data;
            const response = await Media.update(data, data.slug, api_token);
            Toast("Media updated successfully", "success", false);
            setPage("table")
            return response.data
        }
        catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err)
        }
        finally {
            setIsSubmitted(false)
        }
    }
)



export const getMediaThunk = createAsyncThunk(
    'media/get',
    async (_, { getState }) => {
        try {
            const { api_token } = getState().profile.data;
            const response = await Media.get(api_token);
            return response.data

        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err)
        }
    }
)

export const deleteMediaThunk = createAsyncThunk(
    'media/delete',
    async ({ slug, setPage, setIsSubmitted }, { getState }) => {
        try {
            Toast("Deleting media", 'loading', true);
            const { api_token } = getState().profile.data;
            const response = await Media.delete(slug, api_token);
            Toast("Media deleted successfully!.", 'success', false);
            setPage("table")
            return slug

        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err)
        }
        finally {
            setIsSubmitted(false)
        }
    }
)


const initialState = {
    status: "pending",
    data: [],
}

export const mediaSlice = createSlice({
    name: 'media',
    initialState,
    reducers: {
        setStatus: (state, actions) => {
            return {
                ...state,
                ...actions.payload
            }
        },
        updateKeys: (state, action) => {
            return {
                ...state,
                ...action.payload
            }
        },
        resetState: () => {
            return initialState
        }
    },
    extraReducers: {
        [createMediaThunk.fulfilled]: (state, action) => {
            state.data = [
                ...state.data,
                action.payload
            ]
            state.status = "success"
        },
        [createMediaThunk.pending]: (state, action) => {
            state.status = "success"
        },
        [createMediaThunk.rejected]: (state, action) => {
            state.status = "success"
        },

        [getMediaThunk.fulfilled]: (state, action) => {
            state.data = action.payload
            state.status = "success"
        },
        [getMediaThunk.pending]: (state, action) => {
            state.status = "loading"
        },
        [getMediaThunk.rejected]: (state, action) => {
            state.status = "error"
        },

        [updateMediaThunk.fulfilled]: (state, action) => {
            state.data = state.data.map(item => {
                return (item.slug != action.payload.slug) ? item : action.payload
            })
            state.status = "success"
        },
        [updateMediaThunk.pending]: (state, action) => {
            state.status = "loading"
        },
        [updateMediaThunk.rejected]: (state, action) => {
            state.status = "error"
        },

        [deleteMediaThunk.fulfilled]: (state, action) => {
            state.data = state.data.filter(item => item.slug != action.payload)
            state.status = "success"
        },
        [deleteMediaThunk.pending]: (state, action) => {
            state.status = "success"
        },
        [deleteMediaThunk.rejected]: (state, action) => {
            state.status = "success"
        },
    }
})


// Action creators are generated for each case reducer function
export const { setStatus, resetState, updateKeys } = mediaSlice.actions

export default mediaSlice.reducer