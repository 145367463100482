import React, { useEffect, useState } from "react";

import styles from "./styles/index.module.scss"

import { useDispatch, useSelector } from "react-redux";
import _ from "lodash"
import { Toast } from "../../hooks/useToast";
import { getStaticPageThunk, updateStaticPageThunk } from "../../features/StaticPageReducer";

export default function ContactUs() {
    const TITLE_PAGE = 'Edit Contact Form';
    const SUBMIT_TEXT = "Save";

    const dispatch = useDispatch()
    const { data, status } = useSelector(state => state.staticPage);
    const [details, setDetails] = useState({
        email: "",
        facebook_link: "",
        twitter_link: "",
        instagram_link: "",
    })

    useEffect(() => {
        if (status !== 'sucess' || status !== 'loading') {
            dispatch(getStaticPageThunk())
        }
    }, [])

    useEffect(() => {
        setDetails(!_.isEmpty(data?.contact) ? data.contact : {
            email: "",
            facebook_link: "",
            twitter_link: "",
            instagram_link: "",
        })
    }, [data?.contact])

    const handleTextChange = (e) => {
        const { name, value } = e.target
        setDetails(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    const handleNextButton = () => {
        if (_.isEmpty(details.email) || _.isEmpty(details.facebook_link) || _.isEmpty(details.twitter_link) || _.isEmpty(details.instagram_link)) {
            return Toast("Fields should not be empty", 'error', false)
        }

        const body = {
            contact: details
        }
        dispatch(updateStaticPageThunk({ data: body, setPage: () => { } }))
    }




    return (
        <div className={styles.contact}>
            <div className={styles.contentContainer}>
                <h1 className={styles.title}>{TITLE_PAGE}</h1>
                <div className={styles.fieldsContainer}>
                    <div className={styles.fields}>
                        <p className={styles.label}>Email</p>
                        <input type="text" name="email" value={details.email} onChange={handleTextChange} />
                    </div>
                    <div className={styles.fields}>
                        <p className={styles.label}>Facebook Link</p>
                        <input type="text" name="facebook_link" value={details.facebook_link} onChange={handleTextChange} />
                    </div>
                    <div className={styles.fields}>
                        <p className={styles.label}>Twitter Link</p>
                        <input type="text" name="twitter_link" value={details.twitter_link} onChange={handleTextChange} />
                    </div>
                    <div className={styles.fields}>
                        <p className={styles.label}>Instagram Link</p>
                        <input type="text" name="instagram_link" value={details.instagram_link} onChange={handleTextChange} />
                    </div>
                </div>
                <button className={styles.save} onClick={handleNextButton}>
                    {SUBMIT_TEXT}
                </button>
            </div>
        </div>
    )

}