import React from "react";
import TableLayout from "../../components/TableLayout";
import { useMediaHeader } from "../../schemas/tableHeaderSchema";
import { useSelector } from "react-redux";

import styles from "./styles/Table.module.scss"

export default function Table({ setPage, setDetails }) {

    const { data, status } = useSelector(state => state.media);
    const header = useMediaHeader(setPage, setDetails)


    return (
        <div className={styles.cardContainer}>
            <div className={styles.addButtonContainer}>
                <button className={styles.addNew} onClick={() => setPage('new')}>
                    <span>+</span>Add New
                </button>
            </div>
            <div className={styles.contentContainer}>
                <TableLayout headers={header} data={data} status={status} />

            </div>
        </div>
    )
}