import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Blog } from '../Api/Blog';
import { Toast } from '../hooks/useToast';

export const getBlogThunk = createAsyncThunk(
    'blog/get',
    async (_, { getState }) => {
        try {

            const { api_token } = getState().profile.data;
            const response = await Blog.get(api_token);
            return response.data

        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err)
        }
    }
)


export const createBlogThunk = createAsyncThunk(
    'blog/create',
    async ({ data, setPage }, { getState }) => {
        Toast("Creating Blog", "loading", true);
        try {

            const { api_token } = getState().profile.data;
            const body = {};
            body.title = data.title;
            body.author = data.author;
            body.blog_date = data.blog_date;
            body.blog = data.blog;
            const response = await Blog.create(body, api_token);
            Toast(response.message, "success", false);
            setPage("card")
            return response.data

        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err)
        }
    }
)

export const updateBlogThunk = createAsyncThunk(
    'blog/update',
    async ({ data, setPage, setData }, { getState }) => {
        Toast("Updating Blog", "loading", true);
        try {
            const { api_token } = getState().profile.data;
            const response = await Blog.update(data, api_token);
            Toast(response.message, "success", false);
            setData(response.data)
            setPage("details")
            return response.data

        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err)
        }
    }
)

export const updateBlogStatusThunk = createAsyncThunk(
    'blog/update/status',
    async ({ data, setData }, { getState }) => {
        try {
            const { api_token } = getState().profile.data;
            const response = await Blog.update(data, api_token);
            setData(data)
            return data

        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err)
        }
    }
)


export const deleteBlogThunk = createAsyncThunk(
    'blog/delete',
    async ({ slug, setPage }, { getState }) => {
        try {
            Toast("Deleting Blog", 'loading', true);
            const { api_token } = getState().profile.data;
            const response = await Blog.delete(slug, api_token);
            Toast("Blog deleted successfully!.", 'success', false);
            setPage("card")
            return slug

        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err)
        }
    }
)




const initialState = {
    status: "loading",
    data: [],
}

export const blogSlice = createSlice({
    name: 'blog',
    initialState,
    reducers: {
        setStatus: (state, actions) => {
            return {
                ...state,
                ...actions.payload
            }
        },
        updateKeys: (state, action) => {
            return {
                ...state,
                ...action.payload
            }
        },
        resetState: () => {
            return initialState
        }
    },
    extraReducers: {
        [createBlogThunk.fulfilled]: (state, action) => {
            state.data = [
                ...state.data,
                action.payload
            ]
            state.status = "success"
        },
        [createBlogThunk.pending]: (state, action) => {
            state.status = "success"
        },
        [createBlogThunk.rejected]: (state, action) => {
            state.status = "success"
        },

        [getBlogThunk.fulfilled]: (state, action) => {
            state.data = action.payload
            state.status = "success"
        },
        [getBlogThunk.pending]: (state, action) => {
            state.status = "loading"
        },
        [getBlogThunk.rejected]: (state, action) => {
            state.status = "error"
        },

        [updateBlogThunk.fulfilled]: (state, action) => {
            state.data = state.data.map(item => {
                if (item.slug == action.payload.slug) return action.payload
                return item
            })
            state.status = "success"
        },
        [updateBlogThunk.pending]: (state, action) => {
            state.status = "success"
        },
        [updateBlogThunk.rejected]: (state, action) => {
            state.status = "success"
        },

        [updateBlogStatusThunk.fulfilled]: (state, action) => {
            state.data = state.data.map(item => (item.slug != action.payload.slug) ? item : action.payload)
            state.status = "success"
        },
        [updateBlogStatusThunk.pending]: (state, action) => {
            state.status = "loading"
        },
        [updateBlogStatusThunk.rejected]: (state, action) => {
            state.status = "error"
        },

        [deleteBlogThunk.fulfilled]: (state, action) => {
            state.data = state.data.filter(item => item.slug != action.payload)
            state.status = "success"
        },
        [deleteBlogThunk.pending]: (state, action) => {
            state.status = "success"
        },
        [deleteBlogThunk.rejected]: (state, action) => {
            state.status = "success"
        },
    }
})


// Action creators are generated for each case reducer function
export const { setStatus, resetState, updateKeys } = blogSlice.actions

export default blogSlice.reducer