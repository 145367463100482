import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboardThunk } from "../../features/DashboardReducer";

/**Import Styles and Assets */
import styles from "./DashBoard.module.scss";
import dash1 from "./images/dashboard1.svg"

/**Import From Antd */
import { Col, Row } from 'antd';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
const antIcon = <LoadingOutlined style={{ fontSize: 35, color: "white" }} spin />;

export default function DashBoard() {
    const dispatch = useDispatch();
    const { data, status } = useSelector(state => state.dashboard);

    useEffect(() => {
        if (status !== "success") {
            dispatch(fetchDashboardThunk());
        }
    }, []);

    const item = [
        {
            image: dash1,
            number: data?.user_count ? data.user_count : 0,
            info: "Number of Users",
            color1: "#BA2D52",
            color2: "#D93B30"

        },
    ]
    return (
        <div className={styles.dashBoard}>
            <h1 className={styles.contentTitle}>Dashboard</h1>
            <div className={styles.content}>
                <Row gutter={[16, 16]} className={styles.row}>
                    {item.map((i, index) => {
                        return <Card {...{ ...i, status }} index={index} style={(index === 4) ? { width: "44px", height: "44px" } : {}} />
                    })}
                </Row>


            </div>

        </div>
    )
}

export function Card({ image, number, info, color1, color2, status, index, style }) {
    return (
        <Col xxl={6} xl={8} lg={10} sm={20} >
            <div className={styles.card} style={{ backgroundImage: `linear-gradient(180deg, ${color1}, ${color2})` }}>
                <div className={styles.imageBack}>
                    <img src={image} style={style} alt={`Dash Board${index} Image`} />
                </div>
                <h1>{status === "loading" ? <Spin indicator={antIcon} size="large" /> : number}</h1>
                <h4>{info}</h4>

            </div>
        </Col>
    )
}