import React, { useCallback, useState } from "react";
import AddOrUpdateDescription from "./AddOrUpdateDescription";
import AddOrUpdateBlog from "./AddOrUpdateBlog";


const DetailsManagement = ({ setMainPage }) => {

    const [page, setPage] = useState('description');
    const [data, setData] = useState({
        title: '',
        author: '',
        blog_date: '',
        blog: ''
    })

    const handleDetailsBackButton = useCallback(() => {
        setMainPage('card')
    }, [])

    const handleDetailsNextButton = useCallback(() => {
        setPage('blog')
    }, [])


    const handleTextChange = useCallback((name, value) => {
        setData(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    })

    switch (page) {
        case 'description':
            return <AddOrUpdateDescription handleBackButton={handleDetailsBackButton} handleNextButton={handleDetailsNextButton} data={data} handleTextChange={handleTextChange} />
        case 'blog':
            return <AddOrUpdateBlog data={data} setData={setData} setPage={setPage} setMainPage={setMainPage} />
    }

}

export default React.memo(DetailsManagement)