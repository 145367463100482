import { Axios } from "../hooks/useAxiosInstance";

export const Media = (function () {


    const apis = () => ({
        create: async (data, api_token) => await createRecord(data, api_token),
        get: async (api_token) => await getRecord(api_token),
        update: async (data, slug, api_token) => await updateRecord(data, slug, api_token),
        delete: async (slug, api_token) => await deleteRecord(slug, api_token)

    })

    async function createRecord(data, api_token) {
        const url = "admin/media";
        const option = {
            headers: {
                'Authorization': api_token,
            }
        }

        return await Axios.post(url, data, option);
    }
    async function getRecord(api_token) {
        const url = "admin/media";
        const option = {
            headers: {
                'Authorization': api_token,
            }
        }

        return await Axios.get(url, option);
    }
    async function updateRecord(data, slug, api_token) {
        const url = "admin/media/" + slug;
        const option = {
            headers: {
                'Authorization': api_token,
            }
        }

        return await Axios.patch(url, data, option);
    }
    async function deleteRecord(slug, api_token) {
        const url = "admin/media/" + slug;
        const option = {
            headers: {
                'Authorization': api_token,
            }
        }

        return await Axios.delete(url, option);
    }

    return apis();

})()
