import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';


import { ToastContainer } from 'react-toastify';

import { Provider } from 'react-redux'
import { store } from './store.js';

import "react-toastify/dist/ReactToastify.css";
import 'react-confirm-alert/src/react-confirm-alert.css';

// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';

// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import { SocketProvider } from './Context/SocketContext';


// // Require Font Awesome.
// import 'font-awesome/css/font-awesome.css';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <SocketProvider>
      <ToastContainer />
      <App />
    </SocketProvider>
  </Provider>
);

reportWebVitals();
