import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isLoggedIn: false,
    isSocketConnected: false,
}

export const defaultSlice = createSlice({
    name: 'default',
    initialState,
    reducers: {
        updateKey: (state, actions) => {
            return {
                ...state,
                ...actions.payload
            }
        },
    },
})

// Action creators are generated for each case reducer function
export const { updateKey } = defaultSlice.actions

export default defaultSlice.reducer