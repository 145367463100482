import React, { useEffect, useState } from "react";

import ContentManagement from "./ContentManagement";
import CardContainer from "./CardContainer";
import NewCategory from "./NewCategory";
import { useDispatch, useSelector } from "react-redux";
import { getCancerThunk } from "../../features/CancerReducer";
import CardManagement from "./CardManagement";


const CancerLibrary = () => {

    const dispatch = useDispatch()
    const { status } = useSelector(state => state.cancer);
    const [data, setData] = useState({
        name: "",
        image_url: {},
        content: ""
    })
    const [details, setDetails] = useState(undefined);
    const [page, setPage] = useState('card');

    useEffect(() => {
        if (status !== 'success') {
            dispatch(getCancerThunk());
        }

    }, [])

    switch (page) {
        case 'card':
            return <CardContainer setPage={setPage} setDetails={setDetails} />;
        case 'new':
            return <NewCategory setPage={setPage} data={data} setData={setData} />
        case 'content':
            return <ContentManagement setPage={setPage} isEdit={false} data={data} setData={setData} />
        case 'details':
            return <CardManagement handleDetailsPage={setPage} cancerData={details} setDetails={setDetails} />

    }

}

export default React.memo(CancerLibrary)
