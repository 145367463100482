import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { StaticPage } from '../Api/StaticPage';
import { Toast } from '../hooks/useToast';

export const getStaticPageThunk = createAsyncThunk(
    'StaticPage/get',
    async (_, { getState }) => {
        try {
            const { api_token } = getState().profile.data;
            const response = await StaticPage.get(api_token);
            return response.data

        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err)
        }
    }
)



export const updateStaticPageThunk = createAsyncThunk(
    'StaticPage/update',
    async ({ data, setPage }, { getState }) => {
        Toast("Updating Page", "loading", true);
        try {
            const { api_token } = getState().profile.data;
            const record = getState().staticPage.data
            let body = {
                ...record,
                ...data,
            }
            const response = await StaticPage.update(body, api_token);
            Toast('Page updated successfully', "success", false);
            setPage("content")
            return response.data

        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err)
        }
    }
)


const initialState = {
    status: "pending",
    data: [],
}

export const staticPageSlice = createSlice({
    name: 'StaticPage',
    initialState,
    reducers: {
        setStatus: (state, actions) => {
            return {
                ...state,
                ...actions.payload
            }
        },
        updateKeys: (state, action) => {
            return {
                ...state,
                ...action.payload
            }
        },
        resetState: () => {
            return initialState
        }
    },
    extraReducers: {

        [getStaticPageThunk.fulfilled]: (state, action) => {
            state.data = action.payload
            state.status = "success"
        },
        [getStaticPageThunk.pending]: (state, action) => {
            state.status = "loading"
        },
        [getStaticPageThunk.rejected]: (state, action) => {
            state.status = "error"
        },


        [updateStaticPageThunk.fulfilled]: (state, action) => {
            state.data = action.payload
            state.status = "success"
        },
        [updateStaticPageThunk.pending]: (state, action) => {
            state.status = "success"
        },
        [updateStaticPageThunk.rejected]: (state, action) => {
            state.status = "success"
        },


    }
})


// Action creators are generated for each case reducer function
export const { setStatus, resetState, updateKeys } = staticPageSlice.actions

export default staticPageSlice.reducer