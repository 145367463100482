import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Library } from '../Api/Library';
import { Toast } from '../hooks/useToast';

export const createLibraryThunk = createAsyncThunk(
    'library/create',
    async ({ data, setPage, setIsSubmitted }, { getState }) => {
        Toast("Creating Library", "loading", true);
        try {
            const { api_token } = getState().profile.data;
            const body = new FormData();
            body.append("title", data.title)
            body.append("image_url", data.image_url)
            body.append("link", data.link)

            const response = await Library.create(body, api_token);

            Toast(response.message, "success", false);
            setPage("card")
            return response.data

        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err)
        }
        finally {
            setIsSubmitted(false)
        }
    }
)

export const updateLibraryThunk = createAsyncThunk(
    'library/update',
    async ({ data, setPage, setIsSubmitted }, { getState }) => {
        Toast("Updating Library", "loading", true);
        try {

            const { api_token } = getState().profile.data;
            const body = new FormData();
            body.append("title", data.title)
            body.append("image_url", data.image_url)
            body.append("link", data.link)

            const response = await Library.update(body, data.slug, api_token);

            Toast("Library updated successfully", "success", false);
            setPage("card")
            return response.data

        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err)
        }
        finally {
            setIsSubmitted(false)
        }
    }
)



export const getLibraryThunk = createAsyncThunk(
    'library/get',
    async (_, { getState }) => {
        try {
            const { api_token } = getState().profile.data;
            const response = await Library.get(api_token);
            return response.data

        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err)
        }
    }
)

export const deleteLibraryThunk = createAsyncThunk(
    'library/delete',
    async ({ slug, setPage, setIsSubmitted }, { getState }) => {
        try {
            Toast("Deleting library", 'loading', true);
            const { api_token } = getState().profile.data;
            const response = await Library.delete(slug, api_token);
            Toast("Library deleted successfully!.", 'success', false);
            setPage("card")
            return slug

        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err)
        }
        finally {
            setIsSubmitted(false)
        }
    }
)


const initialState = {
    status: "pending",
    data: [],
}

export const librarySlice = createSlice({
    name: 'library',
    initialState,
    reducers: {
        setStatus: (state, actions) => {
            return {
                ...state,
                ...actions.payload
            }
        },
        updateKeys: (state, action) => {
            return {
                ...state,
                ...action.payload
            }
        },
        resetState: () => {
            return initialState
        }
    },
    extraReducers: {
        [createLibraryThunk.fulfilled]: (state, action) => {
            state.data = [
                ...state.data,
                action.payload
            ]
            state.status = "success"
        },
        [createLibraryThunk.pending]: (state, action) => {
            state.status = "success"
        },
        [createLibraryThunk.rejected]: (state, action) => {
            state.status = "success"
        },

        [getLibraryThunk.fulfilled]: (state, action) => {
            state.data = action.payload
            state.status = "success"
        },
        [getLibraryThunk.pending]: (state, action) => {
            state.status = "loading"
        },
        [getLibraryThunk.rejected]: (state, action) => {
            state.status = "error"
        },

        [updateLibraryThunk.fulfilled]: (state, action) => {
            state.data = state.data.map(item => {
                return (item.slug != action.payload.slug) ? item : action.payload
            })
            state.status = "success"
        },
        [updateLibraryThunk.pending]: (state, action) => {
            state.status = "loading"
        },
        [updateLibraryThunk.rejected]: (state, action) => {
            state.status = "error"
        },

        [deleteLibraryThunk.fulfilled]: (state, action) => {
            state.data = state.data.filter(item => item.slug != action.payload)
            state.status = "success"
        },
        [deleteLibraryThunk.pending]: (state, action) => {
            state.status = "success"
        },
        [deleteLibraryThunk.rejected]: (state, action) => {
            state.status = "success"
        },
    }
})


// Action creators are generated for each case reducer function
export const { setStatus, resetState, updateKeys } = librarySlice.actions

export default librarySlice.reducer