import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Cancer } from '../Api/Cancer';
import { Toast } from '../hooks/useToast';

export const createCancerThunk = createAsyncThunk(
    'cancer/create',
    async ({ data, setPage, setData }, { getState }) => {
        Toast("Creating Cancer Category", "loading", true);
        try {

            const { api_token } = getState().profile.data;
            const body = new FormData();
            body.append("name", data.name)
            body.append("image_url", data.image_url)
            body.append("content", data.content)

            const response = await Cancer.create(body, api_token);

            Toast(response.message, "success", false);
            setPage("card")
            setData({})
            return response.data

        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err)
        }
    }
)

export const updateCancerThunk = createAsyncThunk(
    'cancer/update',
    async ({ data, setMainPage, setMainDetails }, { getState }) => {
        Toast("Updating Cancer Category", "loading", true);
        try {

            const { api_token } = getState().profile.data;
            const body = new FormData();
            body.append("name", data.name)
            body.append("image_url", data.image_url)
            body.append("content", data.content)

            const response = await Cancer.update(body, data.slug, api_token);

            Toast("Cancer category updated successfully", "success", false);
            setMainPage("details")
            setMainDetails(response.data)
            return response.data

        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err)
        }
    }
)

export const updateCancerStatusThunk = createAsyncThunk(
    'cancer/update/status',
    async ({ data, setData }, { getState }) => {
        try {
            const { api_token } = getState().profile.data;
            const response = await Cancer.update(data, data.slug, api_token);
            setData(data)
            return data

        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err)
        }
    }
)





export const getCancerThunk = createAsyncThunk(
    'cancer/get',
    async (_, { getState }) => {
        try {

            const { api_token } = getState().profile.data;
            const response = await Cancer.get(api_token);
            return response.data

        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err)
        }
    }
)

export const deleteCancerThunk = createAsyncThunk(
    'cancer/delete',
    async ({ slug, setPage }, { getState }) => {
        try {
            Toast("Deleting cancer category", 'loading', true);
            const { api_token } = getState().profile.data;
            const response = await Cancer.delete(slug, api_token);
            Toast("Cancer category deleted successfully!.", 'success', false);
            setPage("card")
            return slug

        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err)
        }
    }
)


const initialState = {
    status: "loading",
    data: [],
}

export const cancerSlice = createSlice({
    name: 'cancer',
    initialState,
    reducers: {
        setStatus: (state, actions) => {
            return {
                ...state,
                ...actions.payload
            }
        },
        updateKeys: (state, action) => {
            return {
                ...state,
                ...action.payload
            }
        },
        resetState: () => {
            return initialState
        }
    },
    extraReducers: {
        [createCancerThunk.fulfilled]: (state, action) => {
            state.data = [
                ...state.data,
                action.payload
            ]
            state.status = "success"
        },
        [createCancerThunk.pending]: (state, action) => {
            state.status = "success"
        },
        [createCancerThunk.rejected]: (state, action) => {
            state.status = "success"
        },

        [getCancerThunk.fulfilled]: (state, action) => {
            state.data = action.payload
            state.status = "success"
        },
        [getCancerThunk.pending]: (state, action) => {
            state.status = "loading"
        },
        [getCancerThunk.rejected]: (state, action) => {
            state.status = "error"
        },

        [updateCancerThunk.fulfilled]: (state, action) => {
            state.data = state.data.map(item => {
                return (item.slug != action.payload.slug) ? item : action.payload
            })
            state.status = "success"
        },
        [updateCancerThunk.pending]: (state, action) => {
            state.status = "loading"
        },
        [updateCancerThunk.rejected]: (state, action) => {
            state.status = "error"
        },
        [updateCancerStatusThunk.fulfilled]: (state, action) => {
            state.data = state.data.map(item => (item.slug != action.payload.slug) ? item : action.payload)
            state.status = "success"
        },
        [updateCancerStatusThunk.pending]: (state, action) => {
            state.status = "loading"
        },
        [updateCancerStatusThunk.rejected]: (state, action) => {
            state.status = "error"
        },

        [deleteCancerThunk.fulfilled]: (state, action) => {
            state.data = state.data.filter(item => item.slug != action.payload)
            state.status = "success"
        },
        [deleteCancerThunk.pending]: (state, action) => {
            state.status = "success"
        },
        [deleteCancerThunk.rejected]: (state, action) => {
            state.status = "success"
        },
    }
})


// Action creators are generated for each case reducer function
export const { setStatus, resetState, updateKeys } = cancerSlice.actions

export default cancerSlice.reducer