import React, { useState } from "react";


/**Import Styles And Assets */
import styles from "./styles/NewCategory.module.scss"


/**Import Package Component */
import { IoMdArrowBack } from "react-icons/io"
import { AiOutlinePlus } from "react-icons/ai"
import { Upload } from 'antd';
import _ from "lodash"

/**Import Component */
import { Toast } from "../../hooks/useToast";
import { useDispatch } from "react-redux";
import { createLibraryThunk, deleteLibraryThunk, updateLibraryThunk } from "../../features/LibraryReducer";
import { confirmAlert } from 'react-confirm-alert';

const NewCategory = ({ setPage, details = null, isEdit = false, }) => {
    const TITLE_PAGE = isEdit ? "Edit Library" : 'Create Library';
    const SUBMIT_TEXT = isEdit ? "Save" : 'Submit';
    const UPLOAD_BUTTON = (
        <div>
            <AiOutlinePlus />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const dispatch = useDispatch()
    const [isSubmitted, setIsSubmitted] = useState(false);

    const [data, setData] = useState(details ?? {
        title: "",
        image_url: {},
        link: ""
    })

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
        if (!isJpgOrPng) {
            Toast('You can only upload JPG/PNG/JPEG file!', 'error', false);
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            Toast('Image must smaller than 2MB!', 'error', false);
        }
        return isJpgOrPng && isLt2M;
    };

    const handleImageChange = (info) => {
        setData(prev => {
            return {
                ...prev,
                image_url: info?.file?.originFileObj
            }
        })
    }

    const handleTextChange = (e) => {
        const { name, value } = e.target
        setData(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    const handleBackButton = () => {
        setPage('card')
        setData({})
    }

    const handleNextButton = () => {
        if (isSubmitted) return;

        if (_.isEmpty(data.title) || _.isEmpty(data.image_url) || _.isEmpty(data.link)) {
            return Toast("Fields should not be empty", 'error', false)
        }

        setIsSubmitted(true);
        if (isEdit) {
            dispatch(updateLibraryThunk({ data, setPage, setIsSubmitted }))
        }
        else {
            dispatch(createLibraryThunk({ data, setPage, setIsSubmitted }))
        }
    }

    const handleDeleteButton = () => {

        if (isSubmitted) return;

        confirmAlert({
            title: "Confirm Deletion",
            message: "Are you sure you want to delete library?",
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        setIsSubmitted(true)
                        dispatch(deleteLibraryThunk({ slug: data.slug, setPage, setIsSubmitted }))
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
            keyCodeForClose: [8, 32],
        });
    }

    return (
        <div className={styles.newCategory}>
            <div className={styles.optionsContainer}>
                <div className={styles.backContainer} onClick={handleBackButton}>
                    <span className={styles.icon}><IoMdArrowBack /></span>
                    <h3 className={styles.backText} >Back</h3>
                </div>
                {isEdit ? <div className={styles.deleteButtonContainer}>
                    <button className={styles.buttons} onClick={handleDeleteButton}>
                        Delete
                    </button>
                </div> : ''}
            </div>

            <div className={styles.contentContainer}>
                <h1 className={styles.title}>{TITLE_PAGE}</h1>
                <div className={styles.fieldsContainer}>
                    <div className={styles.fields}>
                        <p className={styles.label}>Title</p>
                        <input type="text" name="title" value={data.title} onChange={handleTextChange} />
                    </div>
                    <div className={styles.fields}>
                        <p className={styles.label}>Link</p>
                        <input type="text" name="link" value={data.link} onChange={handleTextChange} />
                    </div>
                    <div className={styles.fields}>
                        <p className={styles.label}>Add Image </p>
                        <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            action=""
                            beforeUpload={beforeUpload}
                            onChange={handleImageChange}
                        >
                            {!_.isEmpty(data.image_url) ?
                                _.isObject(data.image_url) ?
                                    <img src={URL.createObjectURL(data.image_url)} alt="avatar" style={{ width: '100%' }} /> :
                                    <img src={data.image_url} alt="avatar" style={{ width: '100%' }} />
                                : UPLOAD_BUTTON}
                        </Upload>

                    </div>
                </div>
                <button className={styles.createCancerButton} onClick={handleNextButton}>
                    {SUBMIT_TEXT}
                </button>
            </div>
        </div>
    )

}

export default React.memo(NewCategory)