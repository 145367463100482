import React, { useState } from "react";
import ContentManagement from "./ContentManagement";
import DetailsManagement from "./DetailsManagement";
import BlogManagement from "./BlogManagement";

const Blog = () => {

    const [page, setPage] = useState('card');
    const [details, setDetails] = useState(null)
    switch (page) {
        case 'card':
            return <ContentManagement setPage={setPage} setData={setDetails} />
        case 'details':
            return <DetailsManagement setMainPage={setPage} />
        case 'blog':
            return <BlogManagement setMainPage={setPage} data={details} />
    }
}

export default React.memo(Blog)