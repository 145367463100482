import React, { useEffect } from 'react';

import styles from "./styles/CardDetails.module.scss"
import "./styles/page.css"

/**Import Package Component */
import { IoMdArrowBack } from "react-icons/io"
import { useDispatch } from 'react-redux';
import { deleteCancerThunk, updateCancerStatusThunk } from '../../features/CancerReducer';
import { confirmAlert } from 'react-confirm-alert';

const CardDetails = ({ data, setData, setPage, setCurrentPage }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        const linkStylesList = [...document.getElementsByClassName("ls-class-code")]

        linkStylesList.map((item, index) => {
            item.classList.add(`ls-class-code${index % 4}`)
        })

    }, [])


    const handleBackButton = () => {
        setPage('card')
    }


    const handleDeleteButton = () => {
        confirmAlert({
            title: "Confirm Deletion",
            message: "Are you sure you want to delete cancer category?",
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => dispatch(deleteCancerThunk({ slug: data.slug, setPage }))
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
            keyCodeForClose: [8, 32],
        });


    }
    const handleEditButton = () => {
        setCurrentPage("edit")

    }

    const handleStatusButton = () => {
        let body = {
            ...data,
            is_activated: !data.is_activated
        };
        dispatch(updateCancerStatusThunk({ data: body, setData }))
    }

    return (
        <div className={styles.cardManagement}>
            <div className={styles.headerContainer}>
                <div className={styles.backContainer} onClick={handleBackButton}>
                    <span className={styles.icon}><IoMdArrowBack /></span>
                    <h3 className={styles.backText} >Back</h3>
                </div>
                <div className={styles.editButtonContainer}>
                    <button className={`${styles.status} ${styles.buttons} ${styles[data.is_activated ? "activated" : "deactivated"]}`} onClick={handleStatusButton}>
                        {data.is_activated ? "Activated" : "Deactivated"}
                    </button>
                    <button className={`${styles.delete} ${styles.buttons}`} onClick={handleDeleteButton}>
                        Delete
                    </button>
                    <button className={`${styles.edit} ${styles.buttons}`} onClick={handleEditButton}>
                        Edit
                    </button>
                </div>
            </div>

            <div className={`${styles.contentContainer} htmlContainer`} dangerouslySetInnerHTML={{ __html: data.content }} />


        </div >
    )
}

export default React.memo(CardDetails)