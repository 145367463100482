
import { useDispatch } from "react-redux";
import edit from "./images/edit.svg";

import { confirmAlert } from 'react-confirm-alert';
import { updateUserManagementThunk } from "../features/UserManagementReducer";


export function submit(title, message, cb, rejectCB = () => { }) {
    confirmAlert({
        title,
        message,
        buttons: [
            {
                label: 'Yes',
                onClick: () => cb()
            },
            {
                label: 'No',
                onClick: () => rejectCB()
            }
        ],
        closeOnEscape: true,
        closeOnClickOutside: true,
        keyCodeForClose: [8, 32],
    });
}

export function useMediaHeader(setPage, setDetails) {


    const handleEdit = (value) => {
        setDetails(value)
        setPage('edit');

    }
    // const handleDelete = (value) => {
    //     submit('', '', () => dispatch(deleteMediaThunk({ id: value.id })));

    // }

    const header = [
        {
            title: "Media Title",
            dataIndex: "title"
        },
        {
            title: "Media URL",
            dataIndex: "link"
        },

        {
            title: "Actions",
            width: 200,
            textWrap: 'word-break',
            align: "center",
            render: (val) => {
                return <div
                    style={{ margin: "0 auto", justifyContent: "center", display: "flex", flexDirection: "row", color: "#CB0C51", alignItems: "center" }}
                >
                    <img style={{ margin: "0 10px", cursor: "pointer" }} src={edit} alt="Edit" onClick={() => handleEdit(val)} />
                    {/* <img style={{ margin: "0 10px", cursor: "pointer" }} src={bin} alt="Delete" onClick={() => handleDelete(val)} /> */}
                </div>
            }
        }
    ]

    return header;
}



export function useUserManagementHeader() {

    const dispatch = useDispatch();
    return [
        {
            title: 'Username',
            render: (record, val) => {
                return <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                    <img src={val.image_url} alt="User Image" style={{ width: "30px", height: "30px", borderRadius: "100%" }} />
                    <p style={{ margin: "0 15px" }}>{val.username}</p>

                </div>
            }
        },
        {
            title: 'Email Address',
            dataIndex: 'email',
            align: "center",
        },
        {
            title: 'Phone Number',
            dataIndex: 'mobile_no',
            align: "center",
            render: (mobile_no) => {
                return <p style={{ margin: 0, textAlign: "center" }}>{mobile_no || '-'}</p>
            }
        },
        {
            title: 'Status',
            width: 170,
            key: 'action',
            render: (record) => {
                const status = record.is_blocked ? 'unblock' : 'block';
                return <div
                    onClick={() =>
                        submit("Change Status", `Are you sure you want to ${status} this user?`, () => dispatch(updateUserManagementThunk(record.slug)))}
                    style={{ width: "100%", cursor: "pointer", background: "black", padding: "3px 15px", display: "flex", alignItems: "center" }}>
                    <a
                        style={{ width: "100%", color: "#7BE35C", marginRight: "30px", color: (record.is_blocked == 0) ? "#7BE35C" : "#CB0C51" }}>{!record.is_blocked ? "Unblock" : "Block"}</a>
                </div>
            },
        },
    ];
}