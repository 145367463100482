import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    status: "pending",
    data: [],
}

export const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        setStatus: (state, actions) => {
            return {
                ...state,
                ...actions.payload
            }
        },
        dataFetched: (state, action) => {
            return {
                status: 'success',
                data: action.payload
            }
        },
        addThread: (state, action) => {
            return {
                ...state,
                data: [action.payload, ...state.data]
            }
        },
        incrementMessageCount: (state, action) => {
            const slug = action.payload
            return {
                ...state,
                data: state.data.map((item) => {
                    return item.chat_room_slug != slug ? item : {
                        ...item,
                        message_count: item.message_count + 1
                    }
                })
            }
        },
        reorderChat: (state, action) => {
            const i = state.data.findIndex(object => {
                return object.chat_room_slug === action.payload;
            })
            return {
                ...state,
                data: [state.data[i], ...state.data.filter((_, index) => index != i)]
            }
        },

        readAllMessage: (state, action) => {
            const slug = action.payload
            return {
                ...state,
                data: state.data.map((item) => {
                    return item.chat_room_slug != slug ? item : {
                        ...item,
                        message_count: 0
                    }
                })
            }
        },
        resetState: () => {
            return initialState
        }
    }
})


// Action creators are generated for each case reducer function
export const { setStatus, resetState, dataFetched, addThread, incrementMessageCount, readAllMessage, reorderChat } = chatSlice.actions

export default chatSlice.reducer