import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from 'react-router-dom';
import 'antd/dist/antd.min.css';

import ProtectedLayout from "./components/ProtectedLayout";
import ContentLayout from "./components/ContentLayout";


import PublicRoute from "./components/Routes/PublicRoute";
import ProtectedRoute from "./components/Routes/ProtectedRoute";

import Login from "./pages/Login";
import CancerLibrary from "./pages/CancerLibrary"




import { useLocalStorage } from "./hooks/useLocalStorage";
import Transportation from "./pages/Transportation";
import Resources from "./pages/Resources";
import Blog from "./pages/Blog";
import About from "./pages/About";
import Dashboard from "./pages/Dashboard";
import Caregiving from "./pages/Caregiving";
import Library from "./pages/Library";
import Media from "./pages/Media";
import TermsAndCondition from "./pages/TermsAndCondition";
import Chat from "./pages/Chat";
import ContactUs from "./pages/ContactUs";
import Donate from "./pages/Donate";
import UserManagement from "./pages/UserManagement";

function App() {
  const user = useLocalStorage();
  return (
    <Router>
      <Routes>
        <Route exact path={`${process.env.REACT_APP_DOMAIN_DIR}/login`} element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        } />
        <Route exact path={`${process.env.REACT_APP_DOMAIN_DIR}/dashboard`} element={
          <ProtectedRoute>
            <ProtectedLayout selectedRoute="0" isSearchBar={true}>
              <ContentLayout>
                <Dashboard />
              </ContentLayout>
            </ProtectedLayout>
          </ProtectedRoute>
        } />
        <Route exact path={`${process.env.REACT_APP_DOMAIN_DIR}/user-management`} element={
          <ProtectedRoute>
            <ProtectedLayout selectedRoute="1" isSearchBar={true}>
              <ContentLayout>
                <UserManagement />
              </ContentLayout>
            </ProtectedLayout>
          </ProtectedRoute>
        } />
        <Route exact path={`${process.env.REACT_APP_DOMAIN_DIR}/cancer-library`} element={
          <ProtectedRoute>
            <ProtectedLayout selectedRoute="2" isSearchBar={true}>
              <ContentLayout>
                <CancerLibrary />
              </ContentLayout>
            </ProtectedLayout>
          </ProtectedRoute>
        } />
        <Route exact path={`${process.env.REACT_APP_DOMAIN_DIR}/caregiving-101`} element={
          <ProtectedRoute>
            <ProtectedLayout selectedRoute="3" isSearchBar={true}>
              <ContentLayout>
                <Caregiving />
              </ContentLayout>
            </ProtectedLayout>
          </ProtectedRoute>
        } />
        <Route exact path={`${process.env.REACT_APP_DOMAIN_DIR}/air-medical-transportation`} element={
          <ProtectedRoute>
            <ProtectedLayout selectedRoute="4" isSearchBar={true}>
              <ContentLayout>
                <Transportation />
              </ContentLayout>
            </ProtectedLayout>
          </ProtectedRoute>
        } />
        <Route exact path={`${process.env.REACT_APP_DOMAIN_DIR}/blog`} element={
          <ProtectedRoute>
            <ProtectedLayout selectedRoute="5" isSearchBar={true}>
              <ContentLayout>
                <Blog />
              </ContentLayout>
            </ProtectedLayout>
          </ProtectedRoute>
        } />
        <Route exact path={`${process.env.REACT_APP_DOMAIN_DIR}/veteran-resources`} element={
          <ProtectedRoute>
            <ProtectedLayout selectedRoute="6" isSearchBar={true}>
              <ContentLayout>
                <Resources />
              </ContentLayout>
            </ProtectedLayout>
          </ProtectedRoute>
        } />
        <Route exact path={`${process.env.REACT_APP_DOMAIN_DIR}/library`} element={
          <ProtectedRoute>
            <ProtectedLayout selectedRoute="7" isSearchBar={true}>
              <ContentLayout>
                <Library />
              </ContentLayout>
            </ProtectedLayout>
          </ProtectedRoute>
        } />
        <Route exact path={`${process.env.REACT_APP_DOMAIN_DIR}/media`} element={
          <ProtectedRoute>
            <ProtectedLayout selectedRoute="8" isSearchBar={true}>
              <ContentLayout>
                <Media />
              </ContentLayout>
            </ProtectedLayout>
          </ProtectedRoute>
        } />
        <Route exact path={`${process.env.REACT_APP_DOMAIN_DIR}/chat`} element={
          <ProtectedRoute>
            <ProtectedLayout selectedRoute="9" isSearchBar={true}>
              <ContentLayout>
                <Chat />
              </ContentLayout>
            </ProtectedLayout>
          </ProtectedRoute>
        } />

        <Route exact path={`${process.env.REACT_APP_DOMAIN_DIR}/about-us`} element={
          <ProtectedRoute>
            <ProtectedLayout selectedRoute="10" isSearchBar={true}>
              <ContentLayout>
                <About />
              </ContentLayout>
            </ProtectedLayout>
          </ProtectedRoute>
        } />
        <Route exact path={`${process.env.REACT_APP_DOMAIN_DIR}/terms-and-conditions`} element={
          <ProtectedRoute>
            <ProtectedLayout selectedRoute="11" isSearchBar={true}>
              <ContentLayout>
                <TermsAndCondition />
              </ContentLayout>
            </ProtectedLayout>
          </ProtectedRoute>
        } />
        <Route exact path={`${process.env.REACT_APP_DOMAIN_DIR}/contact-us`} element={
          <ProtectedRoute>
            <ProtectedLayout selectedRoute="12" isSearchBar={true}>
              <ContentLayout>
                <ContactUs />
              </ContentLayout>
            </ProtectedLayout>
          </ProtectedRoute>
        } />
        <Route exact path={`${process.env.REACT_APP_DOMAIN_DIR}/donate`} element={
          <ProtectedRoute>
            <ProtectedLayout selectedRoute="13" isSearchBar={true}>
              <ContentLayout>
                <Donate />
              </ContentLayout>
            </ProtectedLayout>
          </ProtectedRoute>
        } />
        <Route path={`${process.env.REACT_APP_DOMAIN_DIR}/*`} element={<Navigate to={`${process.env.REACT_APP_DOMAIN_DIR}/login`} />} />
      </Routes >


    </Router >
  );
}

export default App;
