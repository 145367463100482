import React, { useState } from "react";

import CardDetails from "./CardDetails";
import DetailsManagement from "./DetailsManagement";



const CardManagement = ({ cancerData, handleDetailsPage, setDetails }) => {

    const [page, setPage] = useState('details');

    switch (page) {
        case 'details':
            return <CardDetails data={cancerData} setPage={handleDetailsPage} setCurrentPage={setPage} setData={setDetails} />;
        case "edit":
            return <DetailsManagement data={cancerData} setData={setDetails} setMainPage={setPage} />;

    }

}

export default React.memo(CardManagement)
// <div
//     dangerouslySetInnerHTML={{ __html: html }}
// />