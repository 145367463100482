import React, { useEffect, useMemo, useRef, useState } from "react";

import styles from "./styles/Chat.module.scss"
import { addThread, dataFetched, incrementMessageCount, readAllMessage, reorderChat } from "../../features/ChatReducer";
import { useSocket } from "../../Context/SocketContext";
import ChatContainer from "./ChatContainer";


import useDraggableScroll from 'use-draggable-scroll';
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { resetValue } from "../../features/SearchReducer";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;


export default function Chat() {
    const ref = useRef(null)
    const { onMouseDown } = useDraggableScroll(ref, { direction: 'horizontal' });
    const [chatRoom, setChatRoom] = useState(null)
    const { data, status } = useSelector(state => state.chat)
    const { search } = useSelector(state => state.search)
    const filterThread = useMemo(() => {
        if (!search) return data ?? []
        return data.filter(item => item.username.includes(search))
    }, [search, data]);
    const { isSocketConnected } = useSelector(state => state.default)
    const socket = useSocket();
    const [chatData, setChatData] = useState([]);
    const [chatStatus, setChatStatus] = useState('pending');


    const dispatch = useDispatch();

    useEffect(() => {
        if (isSocketConnected) {
            socket.emit('_getChatThreads')
            socket.on('getChatThreads_', (data) => {
                dispatch(dataFetched(data?.data ?? []))
            })

            socket.on('newThread_', (data) => {
                dispatch(addThread(data?.data ?? []))
                socket.emit('_joinRoom', data?.data.chat_room_slug)
            })
        }


        return () => {
            socket.dispose('getChatThreads_')
            socket.dispose('newThread_')
            dispatch(resetValue());
        }
    }, [isSocketConnected])

    useEffect(() => {

        socket.on("loadChatHistory_", (data) => {
            setChatData(data?.data?.map((_element, index) => {
                return data.data[data.data.length - 1 - index];
            }) ?? [])
            setChatStatus('success')
            dispatch(readAllMessage(chatRoom))
        })

        socket.on("receivedMessage_", ({ data }) => {
            if (data.chat_room_slug == chatRoom) {
                setChatData(prev => {
                    return [
                        data,
                        ...prev
                    ]
                })
            }

            dispatch(reorderChat(data.chat_room_slug))
            if (chatRoom != data.chat_room_slug) {
                dispatch(incrementMessageCount(data.chat_room_slug))
            }
            else {
                socket.emit('_resetMessageCount', { chat_room_slug: chatRoom })
            }

        })

        return () => {
            socket.dispose('loadChatHistory_')
            socket.dispose('receivedMessage_')
        }
    }, [chatRoom])


    const handleThreadClick = (value) => {
        if (value == chatRoom) return;
        setChatStatus("pending")
        setChatRoom(value)
        socket.emit('_loadChatHistory', {
            chat_room_slug: value
        });
    }
    return (
        <div className={styles.cardContainer}>
            <div className={styles.chatThreadContainer} ref={ref} onMouseDown={onMouseDown}>
                {(status == 'pending') ?
                    <div className={styles.loadingContainer}>
                        <Spin indicator={antIcon} />
                    </div> :
                    filterThread.map((item, index) => <div className={`${styles.thread} ${(item.chat_room_slug === chatRoom) ? styles.active : ''}`} key={`chat_thread_${index}`} onClick={() => handleThreadClick(item.chat_room_slug)}>
                        <img src={item.user_image_url} alt="Thread 1" className={styles.threadLogo} />
                        <p className={styles.user_name}>{item.username}</p>
                        {item.message_count ? <span className={styles.tag}>{item.message_count}</span> : ''}
                    </div>)}
            </div>
            <div className={styles.contentContainer}>
                {!chatRoom ? <div className={styles.initialContainer}>
                    <h2 className={styles.title}>Chat With Users</h2>
                    <p className={styles.description}>Send and receive message to/from the users</p>
                </div> : <ChatContainer status={chatStatus} chatRoom={chatRoom} key={chatRoom} data={chatData} />
                }



            </div>
        </div>
    )
}