import React, { useCallback } from "react";

import styles from "./styles/ViewBlog.module.scss";

import { IoMdArrowBack } from "react-icons/io"
import { useDispatch } from "react-redux";
import { deleteBlogThunk, updateBlogStatusThunk } from "../../features/BlogReducer";
import { confirmAlert } from "react-confirm-alert";

const ViewBlog = ({ data, handleViewBackButton, handleViewEditButton, setMainPage, setData }) => {

    const dispatch = useDispatch();
    const handleDeleteButton = useCallback(() => {
        confirmAlert({
            title: "Confirm Deletion",
            message: "Are you sure you want to delete blog?",
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => dispatch(deleteBlogThunk({ slug: data.slug, setPage: setMainPage }))
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
            keyCodeForClose: [8, 32],
        });

    }, [])

    const handleStatusButton = () => {
        let body = {
            ...data,
            is_activated: !data.is_activated
        };
        dispatch(updateBlogStatusThunk({ data: body, setData }))
    }
    return (
        <div className={styles.viewBlog}>
            <div className={styles.headerContainer}>
                <div className={styles.backContainer} onClick={handleViewBackButton}>
                    <span className={styles.icon}><IoMdArrowBack /></span>
                    <h3 className={styles.backText} >Back</h3>
                </div>
                <div className={styles.editButtonContainer}>
                    <button className={`${styles.status} ${styles.buttons} ${styles[data.is_activated ? "activated" : "deactivated"]}`} onClick={handleStatusButton}>
                        {data.is_activated ? "Activated" : "Deactivated"}
                    </button>
                    <button className={`${styles.delete} ${styles.buttons}`} onClick={handleDeleteButton}>
                        Delete
                    </button>
                    <button className={`${styles.edit} ${styles.buttons}`} onClick={handleViewEditButton}>
                        Edit
                    </button>
                </div>
            </div>

            <div className={`${styles.contentContainer} htmlContainer`} >
                <h1 className={styles.title}>{data.title}</h1>
                <p className={styles.name_date}>By <span>{data.author} |</span> <span>{data.blog_date}</span></p>
                <p className={styles.description} dangerouslySetInnerHTML={{ __html: data.blog }} />
            </div>



        </div >
    )
}

export default React.memo(ViewBlog)