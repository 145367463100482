import React, { useCallback, useEffect, useState } from "react";
import Content from "./Content";
import { useDispatch, useSelector } from "react-redux";
import { getStaticPageThunk } from "../../features/StaticPageReducer";
import ContentManagement from "./ContentMangement";

const TermsAndCondition = () => {
    const [page, setPage] = useState('content')
    const dispatch = useDispatch()
    const { data, status } = useSelector(state => state.staticPage)

    useEffect(() => {
        if (status !== 'sucess' || status != 'loading') {
            dispatch(getStaticPageThunk())
        }
    }, [])

    const handleEditButton = useCallback(() => {
        setPage('edit')
    }, [])


    switch (page) {
        case 'content':
            return <Content handleEditButton={handleEditButton} data={(data.terms ?? '')} />
        case 'edit':
            return <ContentManagement setPage={setPage} data={data.terms} />
    }
}

export default React.memo(TermsAndCondition)