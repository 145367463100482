import React, { useState } from "react";


/**Import Styles And Assets */
import styles from "./styles/NewCategory.module.scss"


/**Import Package Component */
import { IoMdArrowBack } from "react-icons/io"
import _ from "lodash"

/**Import Component */
import { Toast } from "../../hooks/useToast";
import { useDispatch } from "react-redux";
import { createMediaThunk, deleteMediaThunk, updateMediaThunk } from "../../features/MediaReducer";
import { confirmAlert } from 'react-confirm-alert';

const NewCategory = ({ setPage, details = null, isEdit = false, }) => {
    const TITLE_PAGE = isEdit ? "Edit Media" : 'Create Media';
    const SUBMIT_TEXT = isEdit ? "Save" : 'Submit';

    const dispatch = useDispatch()
    const [isSubmitted, setIsSubmitted] = useState(false);

    const [data, setData] = useState(details ?? {
        title: "",
        link: ""
    })



    const handleTextChange = (e) => {
        const { name, value } = e.target
        setData(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    const handleBackButton = () => {
        setPage('table')
        setData({})
    }

    const handleNextButton = () => {
        if (isSubmitted) return;

        if (_.isEmpty(data.title) || _.isEmpty(data.link)) {
            return Toast("Fields should not be empty", 'error', false)
        }

        setIsSubmitted(true);
        if (isEdit) {
            dispatch(updateMediaThunk({ data, setPage, setIsSubmitted }))
        }
        else {
            dispatch(createMediaThunk({ data, setPage, setIsSubmitted }))
        }
    }

    const handleDeleteButton = () => {

        if (isSubmitted) return;

        confirmAlert({
            title: "Confirm Deletion",
            message: "Are you sure you want to delete media?",
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        setIsSubmitted(true)
                        dispatch(deleteMediaThunk({ slug: data.slug, setPage, setIsSubmitted }))
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
            keyCodeForClose: [8, 32],
        });
    }

    return (
        <div className={styles.newCategory}>
            <div className={styles.optionsContainer}>
                <div className={styles.backContainer} onClick={handleBackButton}>
                    <span className={styles.icon}><IoMdArrowBack /></span>
                    <h3 className={styles.backText} >Back</h3>
                </div>
                {isEdit ? <div className={styles.deleteButtonContainer}>
                    <button className={styles.buttons} onClick={handleDeleteButton}>
                        Delete
                    </button>
                </div> : ''}
            </div>

            <div className={styles.contentContainer}>
                <h1 className={styles.title}>{TITLE_PAGE}</h1>
                <div className={styles.fieldsContainer}>
                    <div className={styles.fields}>
                        <p className={styles.label}>Title</p>
                        <input type="text" name="title" value={data.title} onChange={handleTextChange} />
                    </div>
                    <div className={styles.fields}>
                        <p className={styles.label}>Link</p>
                        <input type="text" name="link" value={data.link} onChange={handleTextChange} />
                    </div>
                </div>
                <button className={styles.createCancerButton} onClick={handleNextButton}>
                    {SUBMIT_TEXT}
                </button>
            </div>
        </div>
    )

}

export default React.memo(NewCategory)