import { configureStore } from '@reduxjs/toolkit';


import defaultReducer from './features/DefaultReducer';
import ProfileReducer from './features/ProfileReducer';
import DashboardReducer from './features/DashboardReducer';
import SearchReducer from './features/SearchReducer';
import CancerReducer from './features/CancerReducer';
import BlogReducer from './features/BlogReducer';
import StaticPageReducer from './features/StaticPageReducer';
import LibraryReducer from './features/LibraryReducer';
import MediaReducer from './features/MediaReducer';
import ChatReducer from './features/ChatReducer';
import UserManagementReducer from './features/UserManagementReducer';

export const store = configureStore({
  reducer: {
    default: defaultReducer,
    profile: ProfileReducer,
    search: SearchReducer,
    cancer: CancerReducer,
    blog: BlogReducer,
    staticPage: StaticPageReducer,
    library: LibraryReducer,
    media: MediaReducer,
    chat: ChatReducer,
    dashboard: DashboardReducer,
    user: UserManagementReducer,
  },
})