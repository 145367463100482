import React, { useEffect, useState } from "react";

import styles from "./styles/index.module.scss"

import { useDispatch, useSelector } from "react-redux";
import _ from "lodash"
import { Toast } from "../../hooks/useToast";
import { getStaticPageThunk, updateStaticPageThunk } from "../../features/StaticPageReducer";

export default function Donate() {
    const TITLE_PAGE = 'Edit Donate Url';
    const SUBMIT_TEXT = "Save";

    const dispatch = useDispatch()
    const { data, status } = useSelector(state => state.staticPage);
    const [details, setDetails] = useState("")

    useEffect(() => {
        if (status !== 'sucess' || status !== 'loading') {
            dispatch(getStaticPageThunk())
        }
    }, [])

    useEffect(() => {
        setDetails(!_.isEmpty(data?.donate) ? data.donate : "")
    }, [data?.donate])

    const handleTextChange = (e) => {
        const { name, value } = e.target
        setDetails(value)
    }

    const handleNextButton = () => {
        if (_.isEmpty(details)) {
            return Toast("Fields should not be empty", 'error', false)
        }

        const body = {
            donate: details
        }
        dispatch(updateStaticPageThunk({ data: body, setPage: () => { } }))
    }




    return (
        <div className={styles.donate}>
            <div className={styles.contentContainer}>
                <h1 className={styles.title}>{TITLE_PAGE}</h1>
                <div className={styles.fieldsContainer}>
                    <div className={styles.fields}>
                        <p className={styles.label}>Donate URL</p>
                        <input type="text" name="donate" value={details} onChange={handleTextChange} />
                    </div>
                </div>
                <button className={styles.save} onClick={handleNextButton}>
                    {SUBMIT_TEXT}
                </button>
            </div>
        </div>
    )

}