import React, { useEffect } from "react";

import styles from "./styles/ContentManagement.module.scss";

import { Row, Col } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { getBlogThunk } from "../../features/BlogReducer";

const antIcon = <LoadingOutlined style={{ fontSize: 40, color: "grey" }} spin />;

const ContentManagement = ({ setPage, setData }) => {
    const { status, data } = useSelector(state => state.blog)
    const dispatch = useDispatch();

    useEffect(() => {
        if (status !== 'success') {
            dispatch(getBlogThunk());
        }
    }, [])
    const handleCardClick = (index) => {
        setData(data[index])
        setPage('blog')
    }
    return (
        <div className={styles.contentManagement}>
            <div className={styles.addButtonContainer}>
                <button className={styles.addNew} onClick={() => setPage('details')}>
                    <span>+</span>Add New
                </button>
            </div>
            <Row gutter={[30, 40]} className={styles.contentContainer}>
                {!_.isEmpty(data) ? data.map((item, index) => {
                    return < Col xxl={6} xl={8} lg={12} xs={24} className={styles.card} key={index} onClick={() => handleCardClick(index)}>
                        <div className={styles.cardContent}>
                            <h1 className={styles.title}>{item.title}</h1>
                            <p className={styles.name_date}>By <span>{item.author} |</span> <span>{item.blog_date}</span></p>
                            <p className={styles.description} dangerouslySetInnerHTML={{ __html: item.blog }} />
                        </div>
                    </Col>
                }) :
                    <div className={styles.emptyContainer}>
                        {(status == 'loading') ? <Spin indicator={antIcon} /> : <h1>No data found</h1>}
                    </div>
                }
            </Row >
        </div >
    )
}

export default React.memo(ContentManagement)