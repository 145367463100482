import React from "react";
import { Link } from "react-router-dom"
import styles from "./ProtectedLayout.module.scss";
import Navbar from "./Navbar";


import { Layout } from 'antd';
import { confirmAlert } from 'react-confirm-alert';

/**Import Assets */
import { useDispatch } from "react-redux";

import dashboard from "./images/dashboard.svg";
import active_dashboard from "./images/active_dashboard.svg";
import user_management from "./images/user_management.svg";
import active_user_management from "./images/active_user_management.svg";
import library from "./images/library.svg";
import active_library from "./images/active_library.svg";
import caregiving from "./images/caregiving.svg";
import caregiving_active from "./images/caregiving_active.svg";
import medical from "./images/medical.svg";
import active_medical from "./images/active_medical.svg";
import resource from "./images/resource.svg";
import active_resource from "./images/active_resource.svg";
import menu_library from "./images/menu_library.svg";
import menu_library_active from "./images/menu_library_active.svg";
import blog from "./images/blog.svg";
import active_blog from "./images/active_blog.svg";
import about from "./images/about.svg";
import active_about from "./images/active_about.svg";
import media from "./images/media.svg";
import media_active from "./images/media_active.svg";
import chat from "./images/chat.svg";
import chat_active from "./images/chat_active.svg";
import terms from "./images/terms.svg";
import terms_active from "./images/terms_active.svg";
import contact from "./images/contact.svg";
import contact_active from "./images/contact_active.svg";
import donate from "./images/donate.svg";
import donate_active from "./images/donate_active.svg";
import link10 from "./images/logout.svg";


import { updateKey } from "../../features/DefaultReducer";
import { resetState as profileReset } from "../../features/ProfileReducer";
// import { resetState as textReset } from "../../features/StaticTextReducer";
// import { resetState as userReset } from "../../features/UserManagementReducer";
// import { resetState as dashboardReset } from "../../features/DashboardReducer";
import { resetValue as searchReset } from "../../features/SearchReducer";



const { Sider } = Layout;

export default function ProtectedLayout({ selectedRoute, isSearchBar, children }) {

    const dispatch = useDispatch();


    const items = [
        {
            icon: dashboard,
            active: active_dashboard,
            label: "Dashboard",
        },
        {
            icon: user_management,
            active: active_user_management,
            label: "User Management",
        },
        {
            icon: library,
            active: active_library,
            label: "Cancer Library",
        },
        {
            icon: caregiving,
            active: caregiving_active,
            label: "Caregiving 101",
        },
        {
            icon: medical,
            active: active_medical,
            label: "Air Medical Transportation",
        },
        {
            icon: blog,
            active: active_blog,
            label: "Blog",
        },
        {
            icon: resource,
            active: active_resource,
            label: "Veteran Resources",
        },
        {
            icon: menu_library,
            active: menu_library_active,
            label: "Library",
        },
        {
            icon: media,
            active: media_active,
            label: "Media",
        },
        {
            icon: chat,
            active: chat_active,
            label: "Chat",
        },
        {
            icon: about,
            active: active_about,
            label: "About Us",
        },
        {
            icon: terms,
            active: terms_active,
            label: "Terms And Conditions",
        },
        {
            icon: contact,
            active: contact_active,
            label: "Contact Us",
        },
        {
            icon: donate,
            active: donate_active,
            label: "Donate",
        },
        // {
        //     icon: notification,
        //     label: "Notifications",
        // },
        // {
        //     icon: edit,
        //     label: "Change Password",
        // },

    ]

    function handleLogout() {
        dispatch(updateKey({ isLoggedIn: false }));
        dispatch(profileReset());
        // dispatch(textReset())
        // dispatch(userReset())
        // dispatch(dashboardReset())
        dispatch(searchReset())
        localStorage.removeItem("web_token")

    }

    function submit(title, message, cb, rejectCB = () => { }) {
        confirmAlert({
            title,
            message,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => cb()
                },
                {
                    label: 'No',
                    onClick: () => rejectCB()
                }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
            keyCodeForClose: [8, 32],
        });
    }



    return (
        <div className={styles.protectedLayout}>
            <Sider trigger={null} style={{ height: "100vh" }} className={styles.sider} width={290} >
                <div className={styles.children}>

                    <Link className={styles.logo} to={`${process.env.REACT_APP_DOMAIN_DIR}/dashboard`}>
                        <img src='/logo.png' alt="Logo Image" />
                        {/* <h1>Caring Men</h1> */}
                    </Link>
                    <div className={styles.menu}>
                        {items.map(({ icon, active, label }, index) => {
                            return <Link to={`${process.env.REACT_APP_DOMAIN_DIR}/${label.toLowerCase().replace(/[^A-Z0-9]/ig, "-")}`} className={`${styles.item} ${index == selectedRoute ? styles.active : ""}`} key={index}>
                                <img src={(index == selectedRoute) ? active : icon} alt={`Icon${index}`} width={20} height={20} />
                                <p>{label}</p>
                            </Link>
                        })}
                        <Link className={styles.item} onClick={() => submit("Confirm Logout", "Are you sure you want to logout?", () => handleLogout())} >
                            <img src={link10} alt="Icon1" width={20} height={20} />
                            <p>Logout</p>
                        </Link>

                    </div>

                </div>


            </Sider >
            <div className={styles.routeContent}>
                <Navbar isSearchBar={isSearchBar} title={items[selectedRoute].label} />
                <div className={styles.content}>
                    {children}
                </div>
            </div>
        </div >
    )
}